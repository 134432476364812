<template>
  <app-layout>
    <div class="flex flex-col md:flex-row min-h-screen">
      <img v-if="isKpn" src='/images/background_kpn.jpg'
           class="absolute left-0 top-0 h-auto" alt="" style="width: 100%; height: 100%; object-fit: cover;">
      <!-- Left yellow panel -->
      <div class="md:w-1/2 bg-bq-yellow-100 text-black items-center flex flex-col justify-center">
        <div class="space-y-6 md:space-y-20 lg:w-1/2 m-10">
          <div class="flex items-center">
            <n-icon size="40" :component="VideoIcon"/>
            <p class="ml-10 text-xl md:text-3xl">{{ $t('landing_page.usp_0') }}</p>
          </div>
          <div class="flex items-center">
            <n-icon size="40" :component="ScreenShareIcon"/>
            <p class="ml-10 text-xl md:text-3xl">{{ $t('landing_page.usp_1') }}</p>
          </div>
          <div class="flex items-center">
            <n-icon size="40" :component="RepeatIcon"/>
            <p class="ml-10 text-xl md:text-3xl">{{ $t('landing_page.usp_2') }}</p>
          </div>
        </div>
      </div>
      <!-- Right white panel -->
      <div class="md:w-1/2 bg-white p-10 flex flex-col justify-center items-center">
        <div class="lg:w-1/2">
          <p class="block text-3xl font-bold mb-4">{{ $t('landing_page.welcome') }}</p>
          <p class="block mb-4">{{ $t('landing_page.enter_code') }}</p>

          <join-session-form/>
        </div>
        <p class="text-sm fixed right-0 text-center w-full md:text-right md:right-20 bottom-10"
           v-html="$t('landing_page.footer')"/>
      </div>
    </div>
  </app-layout>
</template>

<script setup lang="ts">
import {NIcon} from "naive-ui";
import VideoIcon from '@icons/Video_camera_on.svg';
import ScreenShareIcon from '@icons/Screen_share_turn_on.svg';
import RepeatIcon from '@icons/Repeat.svg';
import AppLayout from "@/layouts/AppLayout.vue";
import JoinSessionForm from "@/components/Index/JoinSessionForm.vue";
import {computed} from "vue";

const isKpn = computed(() => window.location.host.startsWith('kpn'));
</script>