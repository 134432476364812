<template>
  <n-form ref="formRef" :model="formValue" :rules="rules" :show-label="false">
    <n-form-item path="joinSessionCode" class="w-full">
      <n-input
          :placeholder="$t('landing_page.enter_code_placeholder')"
          v-model:value="formValue.joinSessionCode"
      />
    </n-form-item>

    <n-form-item path="username" class="w-full">
      <n-input
          :placeholder="$t('landing_page.enter_username_placeholder')"
          v-model:value="formValue.username"
      />
    </n-form-item>

    <n-form-item>
      <n-button type='primary' class="float-right" @click="handleValidateClick" icon-placement="right"
                :loading="loading">
        <template #icon>
          <n-icon :component="ArrowNarrowRight"/>
        </template>
        {{ $t('landing_page.login') }}
      </n-button>
    </n-form-item>
  </n-form>
</template>

<script setup lang="ts">

import {ArrowNarrowRight} from "@vicons/tabler";
import {FormInst, FormValidationError, NButton, NForm, NFormItem, NIcon, NInput, useMessage} from "naive-ui";
import {useI18n} from "vue-i18n";
import axios from "axios";
import {router} from "@inertiajs/vue3";
import {ref} from "vue";

const formRef = ref<FormInst | null>(null);
const {t} = useI18n();
const message = useMessage();
const loading = ref(false);

const formValue = ref({
  joinSessionCode: '',
  username: ''
});

const rules = {
  joinSessionCode: {
    required: true,
    message: t('landing_page.code_required'),
    trigger: 'blur',
  },
  username: {
    required: true,
    message: t('landing_page.username_required'),
    trigger: 'blur',
  }
};

const joinSession = async () => {
  const response = await axios.post(route('api.shared-sessions.exists', {
    'session_code': formValue.value.joinSessionCode.toUpperCase()
  }));

  if (!response.data.exists) {
    message.error(t('landing_page.session_not_found'));
    return;
  }

  router.visit(route('shared-sessions.show', {
    'shared_session': formValue.value.joinSessionCode.toUpperCase(),
    'user_name': formValue.value.username,
  }));
};

const handleValidateClick = async (e: MouseEvent) => {
  e.preventDefault();

  formRef.value?.validate(async (errors: Array<FormValidationError> | undefined) => {
    loading.value = true;
    if (!errors) {
      await joinSession();
    }
  }).finally(() => loading.value = false);
};
</script>